import * as React from "react"
import { Layout } from "../components/layout"
import { Video } from "../components/video"
import { graphql, Link } from "gatsby"
import { bgBlack, linkContainer, link } from "./index.module.css"

export const query = graphql`
  query {
    shopifyCollection(handle: { eq: "frontpage" }) {
      products {
        ...ProductCard
      }
    }
  }
`

export default function IndexPage(props) {
  const { data, path } = props

  return (
    <div className={bgBlack}>
      <Layout path={path}>
        <Video url="https://www.youtube.com/embed/ex7xvNmiSQU?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=1&start=0&end=0&origin=http://youtubeembedcode.com" />
        <div className={linkContainer}>
          <Link className={link} to={"/shop"}>
            <p>Shop</p>
          </Link>
        </div>
      </Layout>
    </div>
  )
}
